import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import DoneIcon from '@mui/icons-material/Done';
import { green, orange, red } from '@mui/material/colors';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import Link from '@mui/material/Link';
import GitHubIcon from '@mui/icons-material/GitHub';
import Loading from "./Loading";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Tooltip from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { MonacoDiffEditor } from 'react-monaco-editor';
import MonacoEditor from 'react-monaco-editor';

const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080";

export default class Results extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIdx: 0,
            item: {
                code: ""
            },
            address: props.address,
            loading: props.loading
        };
    }

    get_items = (contracts) => {
        let items = [];
        for (let [key, value] of Object.entries(contracts).sort(([,a],[,b]) => a.similarity-b.similarity)) {
            items.push({
                ...value,
                short: key
            });
        }
        return items;
    }

    componentDidMount = () => {
        if (this.props.data) {
            let items = this.get_items(this.props.data.contracts);
            let item = items[0];
            if (item.similarity===0 || item.exact === true) {
                this.setState({
                    code: "",
                });
            } else {
                this.fetchCode(item.url);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.loading && !this.props.loading) {
            let items = this.get_items(this.props.data.contracts);
            let item = items[0];
            if (item.similarity > 0 && !item.exact) {
                this.fetchCode(item.url);
            }
        }
    }

    fetchCode = (url) => {
        this.setState({
            loadingCode: true
        });
        fetch(API_URL + "/fetch_source?path=" + url.replace("https://github.com/", "").replace("/tree", ""))
        .then(res => res.json())
        .then(data => {
            this.setState({
                code: data.data,
                loadingCode: false
            });
        });
    }

    handleListItemClick = (item, index) => {
        if (item.similarity===0 || item.exact === true) {
            this.setState({
                code: "",
            });
        } else {
            this.fetchCode(item.url);
        }
        
        this.setState({
            selectedIdx: index,
            item: item
        });
    };

    static getDerivedStateFromProps(props, state) {
        if(props.loading && (props.address != state.address)) {
            return {
                selectedIdx: 0,
                address: props.address,
                code: "",
                loading: props.loading
            };
        }

        return null;
    }

    render() {
        const loading = this.props.loading;
        let items = [];
        if(!loading){
            items = this.get_items(this.props.data.contracts);            
        }

        const options = {
            readOnly: true
            //renderSideBySide: false
        };
        
        return (
            (<Grid container>
                <Grid item xs={3} xl={2}>
                {loading 
                    ? <Loading/>
                    : 
                        <nav aria-label="contracts">
                        <List sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: 0 }}>
                        {items.map((item, index) => (
                            item.exact ? <ListItemButton key={index} selected={this.state.selectedIdx === index} onClick={() => this.handleListItemClick(item, index)}>
                            <ListItemAvatar>
                            <Avatar sx={{ bgcolor: green[500] }}>
                                <DoneIcon />
                            </Avatar>
                            </ListItemAvatar>
                                <ListItemText primary={item.short} secondary={<Link href={item.url}>{item.repo}: {item.filename}@{item.version}</Link>} />   
                            </ListItemButton> : (item.similarity > 0 ? <ListItemButton key={index} selected={this.state.selectedIdx === index} onClick={() => this.handleListItemClick(item, index)}>
                            <ListItemAvatar>
                                <CircularProgressWithLabel sx={{ color: red[500] }} variant="determinate" value={Math.abs(item.similarity*100)} />
                            </ListItemAvatar>
                                <ListItemText primary={item.short} secondary={<Link href={item.url}>{item.repo}: {item.filename}@{item.version}</Link>} />   
                            </ListItemButton> : <ListItemButton key={index} selected={this.state.selectedIdx === index} onClick={() => this.handleListItemClick(item, index)}>
                            <ListItemAvatar>
                            <Avatar sx={{ bgcolor: orange[500] }}><QuestionMarkIcon/></Avatar>
                            </ListItemAvatar>
                                <ListItemText primary={item.short} />
                            </ListItemButton>)
                        ))}</List>
                    </nav>
                    }
                </Grid>
                <Grid item xs={9} xl={10}>
                <Container maxWidth="100%" sx={{height: '90vh'}} >
                    {(this.state.loadingCode || loading) ?
                        [...Array(25)].map((x, i) =>
                            <Skeleton key={i} animation="wave" sx={{width: "80%"}} />
                        )
                        : (this.state.code ? (items[this.state.selectedIdx].code && <MonacoDiffEditor
                            width="100%"
                            automaticLayout={true}
                            language="sol"
                            theme="vs-dark"
                            original={this.state.code}
                            value={items[this.state.selectedIdx].code}
                            options={options}/>) : 
                            items[this.state.selectedIdx].code && <MonacoEditor
                                width="100%"
                                automaticLayout={true}
                                language="sol"
                                theme="vs-dark"
                                value={items[this.state.selectedIdx].code}
                                options={options}
                        />)                        
                    }
                </Container>
                </Grid>
            </Grid>)
        );
    }
}