import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Skeleton from '@mui/material/Skeleton';

export default function Loading() {
    let items = [];
    for (let i=0; i<10; i++) {
        items.push(<ListItem key={i}>
            <ListItemAvatar>
                <Skeleton animation="wave" variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <Skeleton animation="wave" height={40} width="60%" />
            </ListItem>);
    }
    return <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>{items}</List>;
}