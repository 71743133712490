import './App.css';
import React from "react";
import Search from "./components/Search";
import Container from '@mui/material/Container';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Search/>
      </ThemeProvider>
  );
}

export default App;
