import React from "react";

function Icon(props) {
  const { height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 2560 1440"
    >
      <defs>
        <clipPath id="clipPath16" clipPathUnits="userSpaceOnUse">
          <path d="M0 1080h1920V0H0z"></path>
        </clipPath>
        <linearGradient
          id="linearGradient68"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(254.29257 -254.29257) rotate(47.441 3.473 .043)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#0db5f3" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#1221eb" stopOpacity="1"></stop>
        </linearGradient>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 0 1440)">
        <g>
          <g clipPath="url(#clipPath16)">
            <g transform="translate(615.438 511.367)">
              <path
                fill="#1221eb"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 6.163 1.213 12.09 3.642 17.79 2.425 5.695 5.695 10.689 9.805 14.987 4.106 4.295 8.964 7.609 14.568 9.945 5.602 2.334 11.485 3.503 17.649 3.503 6.163 0 12.09-1.169 17.788-3.503 5.696-2.336 10.598-5.65 14.708-9.945 4.106-4.298 7.376-9.292 9.805-14.987C90.39 12.09 91.607 6.163 91.607 0c0-12.514-4.482-23.439-13.447-32.775-4.11-4.299-9.012-7.661-14.708-10.085-5.698-2.43-11.625-3.642-17.788-3.642-6.164 0-12.047 1.212-17.649 3.642-5.604 2.424-10.462 5.786-14.568 10.085C4.482-23.439 0-12.514 0 0m-19.749 27.595C-23.391 18.91-25.212 9.709-25.212 0c0-9.712 1.821-18.91 5.463-27.593 3.641-8.684 8.728-16.388 15.267-23.111C1.865-57.428 9.337-62.61 17.929-66.253c8.588-3.642 17.833-5.462 27.735-5.462 8.776 0 17.228 2.052 25.352 6.163 8.125 4.106 15.172 9.709 21.151 16.808v-21.571h24.653v198.288H92.167V49.587c-5.419 6.907-12.094 12.326-20.03 16.247-7.94 3.923-16.765 5.884-26.473 5.884-9.902 0-19.147-1.821-27.735-5.464C9.337 62.613 1.865 57.43-4.482 50.706c-6.539-6.722-11.626-14.426-15.267-23.111"
              ></path>
            </g>
            <g transform="translate(783.992 522.013)">
              <path
                fill="#1221eb"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 6.163 2.005 11.998 6.023 17.509 4.013 5.507 9.384 9.945 16.108 13.307 6.723 3.362 13.911 5.042 21.571 5.042 7.656 0 14.848-1.68 21.571-5.042 6.723-3.362 12.089-7.8 16.108-13.307C85.395 11.998 87.404 6.163 87.404 0zm-21.431 15.548c-3.642-8.685-5.463-17.789-5.463-27.314 0-9.525 1.821-18.586 5.463-27.174 3.642-8.592 8.728-16.156 15.268-22.691C.372-68.17 7.935-73.256 16.528-76.899c8.588-3.642 17.649-5.462 27.174-5.462 12.698 0 24.372 3.173 35.017 9.525 10.646 6.347 19.611 15.407 26.895 27.173L84.323-33.057c-10.462-16.064-24-24.091-40.621-24.091-10.461 0-19.61 3.081-27.454 9.244-4.298 3.362-8.08 7.467-11.346 12.325C1.633-30.724 0-26.054 0-21.571h113.458c.56 3.733.84 7.283.84 10.645 0 18.674-6.912 35.111-20.73 49.306-6.352 6.535-13.868 11.577-22.552 15.128-8.685 3.545-17.789 5.323-27.314 5.323-9.525 0-18.63-1.778-27.314-5.323C7.703 49.957.184 44.915-6.163 38.38c-6.54-6.54-11.626-14.147-15.268-22.832"
              ></path>
            </g>
            <g transform="translate(943.58 560.112)">
              <path
                fill="#1221eb"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c6.535 6.535 14.051 11.627 22.551 15.268 8.497 3.643 17.509 5.464 27.034 5.464 22.036 0 40.713-9.342 56.029-28.015L83.762-19.89C74.05-9.621 62.656-4.482 49.585-4.482c-6.163 0-12.002-1.169-17.509-3.502-5.511-2.337-10.322-5.558-14.427-9.664-4.11-4.111-7.38-8.965-9.805-14.568-2.429-5.603-3.642-11.486-3.642-17.649s1.213-12.047 3.642-17.649c2.425-5.603 5.695-10.462 9.805-14.567 4.105-4.11 8.916-7.332 14.427-9.665 5.507-2.337 11.346-3.501 17.509-3.501 13.071 0 24.465 5.134 34.177 15.406l21.852-12.605c-15.316-18.678-33.993-28.015-56.029-28.015-9.525 0-18.537 1.821-27.034 5.463-8.5 3.642-16.016 8.729-22.551 15.268-6.351 6.347-11.346 13.867-14.987 22.551-3.643 8.684-5.463 17.789-5.463 27.314 0 9.525 1.82 18.629 5.463 27.314C-11.346-13.866-6.351-6.35 0 0"
              ></path>
            </g>
            <g transform="translate(1099.983 496.239)">
              <path
                fill="#1221eb"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0-8.592 3.082-15.968 9.245-22.13 6.163-6.163 13.447-9.244 21.851-9.244 3.923 0 7.845.84 11.766 2.52 3.922 1.682 7.284 3.923 10.085 6.724C59.11-15.968 62.192-8.592 62.192 0v84.884h24.373V-55.187H62.192v12.606c-3.738-3.921-8.452-7.192-14.148-9.805-5.698-2.617-11.345-3.922-16.948-3.922-7.472 0-14.616 1.445-21.43 4.343a56.489 56.489 0 00-18.07 12.186c-5.043 5.227-9.013 11.249-11.906 18.069-2.897 6.815-4.342 14.05-4.342 21.71v84.884H0z"
              ></path>
            </g>
            <g transform="translate(1247.962 577.482)">
              <path
                fill="#1221eb"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c6.723 3.173 13.91 4.763 21.571 4.763 6.163 0 12.046-1.122 17.649-3.362C44.822-.84 50.706-4.483 56.869-9.525L33.617-23.252c-4.482 2.052-8.501 3.081-12.046 3.081-7.472 0-13.635-2.618-18.489-7.844-5.231-5.602-7.845-12.047-7.845-19.33v-89.085h-24.092v89.085c0 10.085 2.565 19.33 7.704 27.734C-16.017-11.207-8.965-4.671 0 0"
              ></path>
            </g>
            <path
              fill="#1221eb"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M1353.785 441.052h-23.532v140.071h23.532z"
            ></path>
            <g transform="translate(1341.74 627.346)">
              <path
                fill="#1221eb"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c3.546 0 6.584-1.26 9.105-3.782 2.521-2.522 3.782-5.559 3.782-9.105 0-3.55-1.261-6.583-3.782-9.105-2.521-2.52-5.559-3.782-9.105-3.782-3.55 0-6.583 1.262-9.104 3.782-2.522 2.522-3.782 5.555-3.782 9.105 0 3.546 1.26 6.583 3.782 9.105C-6.583-1.26-3.55 0 0 0"
              ></path>
            </g>
            <g transform="translate(1405.696 639.34)">
              <path
                fill="#1221eb"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h23.812v-58.216h21.01V-83.43h-21.01v-114.858H0V-83.43h-21.011v25.214H0z"
              ></path>
            </g>
            <g transform="translate(1506.07 500.722)">
              <path
                fill="#1221eb"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0-6.54 1.633-12.655 4.903-18.349 3.265-5.699 7.656-10.225 13.167-13.587 5.506-3.361 11.53-5.042 18.069-5.042 6.535 0 12.607 1.632 18.209 4.903 5.603 3.265 9.989 7.747 13.167 13.445C70.688-12.935 72.277-6.724 72.277 0v80.401H96.93V-57.148c0-8.405-1.541-16.433-4.623-24.092-3.081-7.657-7.423-14.288-13.026-19.891-5.791-5.787-12.375-10.269-19.75-13.447-7.381-3.174-15.176-4.763-23.392-4.763-10.645 0-20.499 2.71-29.555 8.125-9.061 5.419-16.949 13.262-23.672 23.532L4.763-75.077c3.922-6.163 8.588-10.878 14.007-14.149 5.415-3.264 11.206-4.901 17.369-4.901 9.897 0 18.489 3.641 25.773 10.925 3.362 3.362 5.927 7.283 7.704 11.766 1.772 4.482 2.661 9.245 2.661 14.288v9.805c-4.298-4.483-9.713-8.08-16.248-10.786-6.539-2.705-13.167-4.062-19.89-4.062-8.22 0-15.968 1.541-23.252 4.623a60.323 60.323 0 00-19.33 13.026c-5.79 5.603-10.273 12.09-13.446 19.47-3.178 7.375-4.763 15.17-4.763 23.39v82.083H0z"
              ></path>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="url(#linearGradient68)"
                  stroke="none"
                  d="M335.775 698.836c-9.881-1.035-17.849-9.174-18.696-19.073-.717-8.385 3.494-15.835 10.049-19.82a4.472 4.472 0 002.168-3.773l2.135-218.322c.012-1.19-.537-2.337-1.521-3.007-8.832-6.014-14.242-16.674-12.625-28.487 1.771-12.935 12.193-23.319 25.134-25.039 16.407-2.182 30.621 9.155 33.103 24.43.214 1.314 1.165 2.388 2.427 2.815l112.349 37.957a11.785 11.785 0 0010-1.177c6.169-3.837 13.52-5.95 21.383-5.663 19.167.7 35.073 16.077 36.371 35.212 1.149 16.962-8.876 31.734-23.442 37.699-3.325 1.361-5.928 4.047-6.998 7.477L495.026 624.53c-.553 1.772-.255 3.712.851 5.204a16.758 16.758 0 013.233 11.503c-.677 7.778-6.848 14.221-14.596 15.192-4.62.579-8.925-.72-12.266-3.233-3.064-2.304-7.132-2.794-10.794-1.664l-100.907 31.151a4.434 4.434 0 00-2.84 2.668c-3.005 7.946-10.665 13.603-19.664 13.604-.748 0-1.504-.039-2.268-.119m21.475-35.98c-1.274 1.375-1.6 3.419-.703 5.065.1.184.198.369.292.556a4.457 4.457 0 005.191 2.28l100.78-28.535c.861-.317 2.34-1.187 2.735-3.56.002-.022.015-.036.02-.057a16.756 16.756 0 011.198-5.252c1.452-3.536.909-7.583-1.072-10.852l-28.049-46.303zm-5.237-223.439a7.087 7.087 0 01-.127.025c-1.681.317-2.931 1.727-3.004 3.435l-8.961 210.248a4.344 4.344 0 003.277 4.395l.061.015a4.644 4.644 0 004.694-1.515l80.213-95.465-72.356-119.444a3.64 3.64 0 00-3.11-1.76c-.228 0-.457.022-.687.066m144.053 73.803l-49.845 53.731 28.808 52.964c1.159 2.131 3.492 3.246 5.908 3.042.463-.04.932-.06 1.404-.06h.052c2.865.009 5.384-1.873 5.952-4.682l20.015-98.712c.637-3.142-1.044-6.233-3.955-7.578l-.373-.174a7 7 0 00-3.008-.682 6.735 6.735 0 00-4.958 2.151m-124.504-87.343l-.027.045c-.671 1.095-.675 2.472-.061 3.599l65.541 120.503 44.088-52.47c2.436-2.9 3.457-6.802 2.502-10.468a37.87 37.87 0 01-1.218-9.557c0-.167.001-.334.003-.5.065-4.871-2.723-9.319-7.215-11.2l-99.037-41.487a3.597 3.597 0 00-1.393-.282c-1.266 0-2.482.67-3.183 1.817"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;