import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import Results from "./Results";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useSearchParams } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import SplitButton from './SplitButton';
import SvgIcon from '@mui/material/SvgIcon';
import Icon from "./Icon";
import IconButton from '@mui/material/IconButton';

export default function Search() {
    let [query, setSearchParams] = useSearchParams();
    const [address, setAddress] = useState(query.get("address") || "");
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const [loadingRepos, setLoadingRepos] = useState(false);
    const [repos, setRepos] = useState([]);
    const [inputError, setInputError] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(query.get("chain") || 0);

    const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080";

    useEffect(() => {
        if (query.get("address")) {
            fetchResults();
        }
        fetchRepos();
    }, []);

    function _handleTextFieldChange(e){
        setAddress(e.target.value);
        if(!e.target.value.match(/0x[a-fA-F0-9]{40}/)) {
            setInputError("Incorrect address");
        } else {
            setInputError("");
        }
    }

    const chains = {
        0: "Ethereum",
        1: "BSC",
        2: "Polygon",
        3: "Arbitrum",
        4: "Optimism",
        5: "Base"
    }

    function fetchResults() {
        if(inputError) {
            return;
        }
        setLoading(true);
        setSearchParams({"address": address, "chain": selectedIndex});

        fetch(API_URL + "/find_similar?address=" + address + "&chain=" + chains[selectedIndex])
        .then(res => res.json())
        .then(data => {
            if (!data.error) {
                setResults(data);
            } else {
                setResults(null);
                setInputError(data.errorText);
            }
            setLoading(false);
        });
    }

    function fetchRepos() {
        setLoadingRepos(true);

        fetch(API_URL + "/get_repos")
        .then(res => res.json())
        .then(data => {
            setLoadingRepos(false);
            setRepos(data);
        });
    }

    function _keyPress(e) {
        if(e.keyCode === 13){
            fetchResults();
         }
    }

    const hideAutoFillColorStyle = {
        WebkitBoxShadow: '0 0 0 1000px #121212 inset'
    };

    return (
        <div>
        <Grid container>
        <Grid item xs={3} xl={2}>
            <Container sx={{marginTop:3, marginBottom: 2, textAlign: "center", verticalAlign: "middle"}}>
                <h3><Link color="inherit" underline="none" href="/">✨ contract-diff.xyz</Link></h3>
            </Container>
        </Grid>
        <Grid item xs={9} xl={5}>
            <Container sx={{marginTop:2, marginBottom: 2, justifyContent: 'left'}} maxWidth="100%">
                <TextField inputProps={{ style: hideAutoFillColorStyle }} 
                    fullWidth
                    id="standard-name"
                    value={address}
                    error={address && inputError ? true : false}
                    helperText={address && inputError ? inputError : ""}
                    autoFocus
                    onKeyDown={_keyPress.bind(this)}
                    label="Contract Address"
                    onChange={_handleTextFieldChange.bind(this)}
                    InputProps={{
                        endAdornment: <Button 
                            startIcon={<SearchIcon />} 
                            color="success" 
                            onClick={fetchResults.bind(this)}
                            disabled={inputError ? true: false}
                            variant="contained">
                                Scan
                            </Button>,
                        startAdornment: <SplitButton selectedIndex={selectedIndex} setInputError={setInputError} setSelectedIndex={setSelectedIndex}/>}}/>
            </Container>
        </Grid>
        </Grid>
        {(loading || results) ? <Results loading={loading} data={results} address={address}/> :
        <Grid container>
            <Grid item xs={3} xl={2}>
            
            </Grid>
            <Grid item xs={9} xl={5}>
                <Container sx={{marginBottom: 2}}>
                <Paper elevation={12} sx={{padding: 2}}>
                    contract-diff.xyz helps to find differences in contract forks using <Link href="https://en.wikipedia.org/wiki/SimHash">SimHashes</Link>.
                    For each contract there may be one of these states: exact match (MD5 hash is identical), incomplete match (SimHashes are nearly identical) and no match (unknown contract).
                    Supported chains: Ethereum mainnet, BSC, Polygon, Arbitrum, Optimism, Base.
                    <p>Check out <Link href="https://twitter.com/RektHQ/status/1387399124090294276">Uranium finance</Link> example: <Link href="https://www.contract-diff.xyz/?address=0xa08c4571b395f81fbd3755d44eaf9a25c9399a4a&chain=1">0xa08c4571b395f81fbd3755d44eaf9a25c9399a4a</Link></p>
                    <p>Note: it may take up to 2 minutes for the backend API container to start up, please be patient.</p>
                </Paper>
                </Container>
                <Container>
                <Paper elevation={12} sx={{padding: 2}}>
                    Contracts:
                    {loadingRepos ? 
                        <List>
                        {[...Array(7).keys()].map((_, i) => {
                            return <ListItem key={i}>
                                <ListItemAvatar>
                                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                                </ListItemAvatar>
                                <Skeleton animation="wave" height={40} width="60%" />
                            </ListItem>
                        })}    
                        </List>
                    :
                        <List>
                        {repos.map((repo, i) => {
                            return <ListItem key={i}>
                                <ListItemAvatar>
                                <Avatar>
                                    <GitHubIcon />
                                </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Link href={repo.git}>{repo.git}</Link>} secondary={repo.tag ? repo.tag : (repo.commit ? repo.commit : "all tags")} />
                            </ListItem>
                        })}    
                    </List>}
                </Paper>
                
                </Container>
            </Grid>
            <Grid item xs={3} xl={2}>
            
            </Grid>
            <Grid item xs={9} xl={9}>
            <Container sx={{marginTop:3}}>
                <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <Link href="https://decurity.io" target="_blank">
                        <Icon width={150}/>

                    </Link>
                </Stack>
                <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                    
                    <Link href="https://twitter.com/DecurityHQ" target="_blank">
                        <IconButton size="large">
                           <TwitterIcon />
                        </IconButton>
                    </Link>
                    <Link href="https://github.com/Decurity" target="_blank">
                        <IconButton size="large">
                            <GitHubIcon />
                        </IconButton>
                    </Link>
                    <Link href="https://www.linkedin.com/company/decurity/" target="_blank">
                        <IconButton size="large">
                            <LinkedInIcon />
                        </IconButton>
                    </Link>
                </Stack>
            </Container>
            </Grid>
        </Grid>
        
        }
        </div>
    );
}